<template>
  <div class="BaseData">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="我的资料">
        <a-form ref="userformRef" :model="userInformation" :rules="userrules">
          <a-form-item label="用户名" ref="username" name="username">
            <a-input
              v-model:value="userInformation.username"
              placeholder="请输入"
              style="width: 250px"
            />
          </a-form-item>
          <a-form-item label="公司名称">
            <span>{{ userInformation.company_name }}</span>
          </a-form-item>
          <a-form-item label="联系人" ref="contact" name="contact">
            <a-input
              v-model:value="userInformation.contact"
              placeholder="请输入"
              style="width: 250px"
            />
          </a-form-item>
          <a-form-item label="手机号码">
            <span>{{ userInformation.phone || "未绑定" }}</span>
          </a-form-item>
          <a-form-item label="固定电话" ref="tel" name="tel">
            <a-input
              placeholder="请输入"
              v-model:value="userInformation.tel"
              style="width: 250px"
            />
          </a-form-item>
          <a-form-item label="电子邮箱" ref="email" name="email">
            <a-input
              placeholder="请输入"
              v-model:value="userInformation.email"
              style="width: 250px"
            />
          </a-form-item>
          <a-form-item label="公司地址">
            <a-input
              placeholder="请输入"
              v-model:value="userInformation.address"
              style="width: 250px"
            />
          </a-form-item>
          <a-form-item label="机器人数量">
            <span>{{ userInformation.robots || 0 }}</span>
          </a-form-item>
          <!-- <a-form-item label="仟客appid">
            <a-input
              v-model:value="userInformation.kd_appid"
              style="width: 250px"
            />
          </a-form-item>
          <a-form-item label="仟客密钥">
            <a-input
              v-model:value="userInformation.kd_secret"
              style="width: 250px"
            />
          </a-form-item>
          <a-form-item label="仟客公司ID">
            <a-input
              v-model:value="userInformation.kd_did"
              style="width: 250px"
            />
          </a-form-item> -->
          <div class="tips">
            <a-button type="primary" @click="save">保存</a-button>
          </div>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="头像">
        <div class="tab2">
          <a-upload
            v-model:file-list="fileList"
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            action="/user/upload_avatar_file"
            :headers="{
              access_token: access_token,
            }"
            :before-upload="beforeUpload"
            @change="handleChange"
          >
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
            <div v-else>
              <loading-outlined v-if="loading"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">点击上传头像</div>
            </div>
          </a-upload>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="密码">
        <a-form ref="formRef" :model="formState" :rules="rules">
          <a-form-item ref="oldPwd" label="当前密码" name="oldPwd">
            <a-input v-model:value="formState.oldPwd" style="width: 250px" />
          </a-form-item>
          <a-form-item ref="newPwd" label="新密码" name="newPwd">
            <a-input
              v-model:value="formState.newPwd"
              style="width: 250px"
              type="password"
            />
          </a-form-item>
          <a-form-item
            ref="confirmNewPwd"
            label="确认新密码"
            name="confirmNewPwd"
          >
            <a-input
              v-model:value="formState.confirmNewPwd"
              style="width: 250px"
              type="password"
            />
          </a-form-item>
          <div class="tips">
            <a-button type="primary" @click="changPwd">确认修改</a-button>
            <a-button @click="resetForm" class="resetBtn"></a-button>
          </div>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="4" tab="账号绑定">
        <div class="tab4">
          <div class="item">
            <img src="~@/assets/koudai.png" />
            {{
              userInformation.kd_binding == 1
                ? "已绑定，您可以使用仟客推送功能"
                : "未绑定，绑定仟客成功后，即可使用仟客推送功能"
            }}
            <span class="bangding" @click="handleBangding('kd_binding')">{{
              userInformation.kd_binding == 1 ? "解除绑定" : "立即绑定"
            }}</span>
          </div>
          <div class="item">
            <img src="~@/assets/gongzhonghao.png" />
            {{
              userInformation.wx_binding == 1
                ? "已绑定微信公众号，您可以使用微信公众号推送功能"
                : "未绑定，关注公众号成功后，即可使用微信推送功能"
            }}
            <span class="bangding" @click="handleBangding('wx_binding')">
              {{
                userInformation.wx_binding == 1
                  ? `解除绑定(${userInformation.wx_account})`
                  : "立即绑定"
              }}
            </span>
            <!-- <span style="margin-left:15px">已绑定微信号:{{ userInformation.wx_account }} </span> -->
          </div>
          <div class="item">
            <img src="~@/assets/bind.png" />
            {{
              userInformation.phone_binding == 1
                ? "已绑定，您可以使用手机验证码登录功能"
                : "未绑定，绑定手机成功后，即可使用手机验证码登录功能"
            }}
            <span class="bangding" @click="handleBangding('phone_binding')">{{
              userInformation.phone_binding == 1 ? "解除绑定" : "立即绑定"
            }}</span>
          </div>
          <div class="item">
            <WechatOutlined />
            {{
              userInformation.wx_login_binding == 1
                ? "已绑定，您可以使用微信扫码登入"
                : "未绑定，绑定微信成功后，即可使用微信扫码登录功能"
            }}
            <span
              class="bangding"
              @click="handleBangding('wx_login_binding')"
              >{{
                userInformation.wx_login_binding == 1 ? "解除绑定" : "立即绑定"
              }}</span
            >
          </div>

          <!-- <div class="item">
            <img src="~@/assets/xiaochengxu.png" />
            添加小程序后可使用小程序录音
            <span class="bangding" @click="handleBangding('xiaochenxu')"
              >扫一扫</span
            >
          </div> -->
        </div>
      </a-tab-pane>
    </a-tabs>
    <a-modal
      v-model:visible="visibleVx"
      centered
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      title="绑定微信"
      :footer="null"
      @cancel="handleOk('weixin')"
    >
      <!-- <p>Some contents...</p>
      <p>Some contents...</p>
      <p>Some contents...</p> -->
      <!-- <div id="login_container"></div> -->
      <wxlogin
        :appid="appid"
        :state="access_token"
        scope="snsapi_login"
        redirect_uri="/user/bind_push"
      ></wxlogin>
    </a-modal>
    <a-modal
      v-model:visible="visibleTel"
      centered
      :maskClosable="false"
      :title="unboundName === 'phone_binding' ? '解除手机绑定' : '绑定手机'"
      :footer="null"
      :destroyOnClose="true"
    >
      <a-form class="model2">
        <a-form-item>
          <a-input
            v-model:value="bdPhone.phone"
            type="number"
            placeholder="请输入手机号"
            :disabled="disabled"
          >
            <template #prefix
              ><MobileOutlined style="color: rgba(0, 0, 0, 0.25)"
            /></template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            v-model:value="bdPhone.code"
            type="number"
            placeholder="短信验证码"
          >
            <template #prefix
              ><SafetyOutlined style="color: rgba(0, 0, 0, 0.25)"
            /></template>
          </a-input>
          <a-button
            style="margin-left: 10px"
            :disabled="sendCodeBtn"
            @click="sendCode"
            >{{ sendCodeBtn ? `${time}秒后再操作` : "发送" }}</a-button
          >
        </a-form-item>
        <a-button type="primary" style="width: 100%" @click="queren"
          >确认</a-button
        >
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="visibleGzh"
      :footer="null"
      centered
      :maskClosable="false"
      :width="600"
      title="微信公众号扫码"
      @cancel="okInit"
    >
      <div class="model3">
        <div class="model3item">
          <img :src="follow_url" />
          <span>关注公众号第一步</span>
        </div>
        <div class="model3item">
          <img :src="code_url" />
          <span>绑定公众号第二步</span>
        </div>
      </div>
    </a-modal>
    <a-modal
      v-model:visible="visibleQk"
      centered
      :maskClosable="false"
      title="仟客绑定"
      :width="650"
      :footer="null"
      @cancel="okInit"
    >
      <!-- <div class="model4 kdbind"> -->
      <iframe style="width: 100%; height: 550px" :src="code_url" />
      <!-- </div> -->
    </a-modal>
    <a-modal
      v-model:visible="visibleXcx"
      :footer="null"
      centered
      :maskClosable="false"
      title="扫一扫"
      :width="350"
    >
      <div class="model4">
        <img
          src="https://gw.400fzy.com/static/upload/babdec0fd41fb6cb19ebe5a747962f50.jpg"
        />
      </div>
    </a-modal>
    <a-modal
      v-model:visible="visibleUnbound"
      centered
      :maskClosable="false"
      title="解绑"
      :width="350"
      okText="确认"
      cancelText="取消"
      @ok="okUnbound"
    >
      <div>
        是否确认解除{{
          unboundName === "wx_login_binding"
            ? "微信扫码登入"
            : unboundName === "kd_binding"
            ? "仟客推送"
            : "微信公众号推送"
        }}绑定？
      </div>
    </a-modal>
  </div>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import {
  PlusOutlined,
  LoadingOutlined,
  WechatOutlined,
  MobileOutlined,
  SafetyOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import {
  getUserInformation,
  getChangePwd,
  getSendCode,
  getBdPhone,
  getSave,
  getBdWeixin,
  getBdQianke,
  getAppid,
  postPhone,
  postWeixinLogin,
  postKdDelete,
  postWxpushDelete,
} from "../../service/basedata";
import { regTest, telTest, emailTest } from "../../utils/regExp";
// import WxLogin from "./wxLogin";
// function getBase64(img, callback) {
//   console.log(img);
//   const reader = new FileReader();
//   reader.addEventListener("load", () => callback(reader.result));
//   reader.readAsDataURL(img);
// }
import wxlogin from "vue-wxlogin";
export default {
  name: "BaseData",
  setup() {
    const rules = {
      oldPwd: [
        {
          required: true,
          message: "请输入当前密码",
          trigger: "blur",
        },
        {
          min: 6,
          max: 16,
          message: "长度6-16位",
          trigger: "blur",
        },
      ],
      newPwd: [
        {
          required: true,
          message: "请输入新密码",
          trigger: "blur",
        },
        {
          min: 6,
          max: 16,
          message: "长度6-16位",
          trigger: "blur",
        },
      ],
      confirmNewPwd: [
        {
          required: true,
          message: "请确认新密码",
          trigger: "blur",
        },
        {
          min: 6,
          max: 16,
          message: "长度6-16位",
          trigger: "blur",
        },
      ],
    };
    const userrules = {
      username: [
        {
          message: "请输入用户名",
          trigger: "blur",
        },
        {
          min: 3,
          max: 16,
          message: "长度3-16位",
          trigger: "blur",
        },
      ],
      contact: [
        {
          required: true,
          message: "请输入联系人",
          trigger: "blur",
        },
        {
          min: 2,
          max: 5,
          message: "长度2-5位",
          trigger: "blur",
        },
      ],
      tel: [
        {
          message: "格式不正确,例：0913-5549400",
          trigger: "blur",
          validator: telTest,
        },
      ],
      email: [
        {
          message: "格式不正确,例:1461994847@qq.com",
          trigger: "blur",
          validator: emailTest,
        },
      ],
    };
    const userformRef = ref();
    const state = reactive({
      visibleVx: false,
      visibleTel: false,
      visibleGzh: false,
      visibleXcx: false,
      visibleQk: false,
      userInformation: {},
      bdPhone: {
        phone: undefined,
        code: "",
      },
      sendCodeBtn: false,
      time: 60,
      timer: null,
      access_token: sessionStorage.getItem("token"),
      follow_url: undefined,
      code_url: undefined,
      appid: undefined,
      visibleUnbound: false, //接触绑定弹框
      unboundName: undefined, //解除绑定的id
      disabled: false,
    });
    const formRef = ref();
    const formState = reactive({
      oldPwd: "",
      newPwd: "",
      confirmNewPwd: "",
    });
    // const changePassword = reactive({
    //   oldPwd: "",
    //   newPwd: "",
    //   confirmNewPwd: "",
    // });
    const fileList = ref([]);
    const loading = ref(false);
    const imageUrl = ref("");
    onMounted(() => {
      init();
    });
    const init = async () => {
      let res = await getUserInformation({
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      state.userInformation = res.data.data;
      imageUrl.value = state.userInformation.avatar;
    };
    const handleChange = (info) => {
      console.log(info);
      console.log(info.file.status);
      if (info.file.status === "uploading") {
        loading.value = true;
        return;
      }
      if (info.file.status === "done") {
        let uploadImg = info.file.response;
        // console.log(info.file.response);
        uploadImg = JSON.parse(uploadImg.substring(0, uploadImg.indexOf("}") + 1))
        // console.log((uploadImg));
        if (uploadImg.data.length > 0) {
          imageUrl.value = uploadImg.data.url;
        }
        message.error(uploadImg.msg);
        // imageUrl.value = info.file.response.data.url;
        // message.success("上传成功");


        // Get this url from response in real world.
        // getBase64(info.file.originFileObj, (base64Url) => {
        //   imageUrl.value = base64Url;
        //   loading.value = false;
        // });
      }

      if (info.file.status === "error") {
        loading.value = false;
        message.error("upload error");
      }
    };

    const beforeUpload = (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";

      if (!isJpgOrPng) {
        message.error("You can only upload JPG file!");
      }

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }

      return isJpgOrPng && isLt2M;
    };
    const handleBangding = (key) => {
      console.log(key);
      if (state.userInformation[key] == 1) {
        // okUnbound(key)
        state.unboundName = key;
        if (key === "phone_binding") {
          state.bdPhone.phone = state.userInformation.phone;
          state.disabled = true;
          state.visibleTel = true;
        } else {
          console.log(11111111);
          state.visibleUnbound = true;
        }
        return;
      }
      switch (key) {
        case "wx_login_binding":
          state.visibleVx = true;
          GenerateQrcode();
          break;
        case "phone_binding":
          state.visibleTel = true;
          break;
        case "wx_binding":
          state.visibleGzh = true;
          getBdWeixinFun();
          break;
        case "xiaochenxu":
          state.visibleXcx = true;
          break;
        case "kd_binding":
          state.visibleQk = true;
          getBdQiankeFun();
          break;
      }
      // console.log(key);
    };
    const okUnbound = () => {
      // console.log(play);
      switch (state.unboundName) {
        case "wx_login_binding":
          postWeixinLoginFun();
          break;
        case "phone_binding":
          // queren(2);
          // postPhoneFun();
          break;
        case "wx_binding":
          // postWeixinFun();
          postWxpushDeleteFun();
          break;
        case "xiaochenxu":
          break;
        case "kd_binding":
          postKdDeleteFun();
          break;
      }
    };
    //解除公众号推送
    const postWxpushDeleteFun = async () => {
      const res = await postWxpushDelete({
        access_token: sessionStorage.getItem("token"),
        type: 2,
      });
      console.log(res);
      if (res.data.status == 100000) {
        message.success(res.data.msg);
        state.visibleUnbound = false;
        init();
      } else {
        message.error(res.data.msg);
      }
    };
    //接触仟客推送
    const postKdDeleteFun = async () => {
      const res = await postKdDelete({
        access_token: sessionStorage.getItem("token"),
        type: 2,
      });
      console.log(res);
      if (res.data.status == 100000) {
        message.success(res.data.msg);
        state.visibleUnbound = false;
        init();
      } else {
        message.error(res.data.msg);
      }
    };
    //解除微信登入绑定
    const postWeixinLoginFun = async () => {
      const res = await postWeixinLogin({
        access_token: sessionStorage.getItem("token"),
        type: 2,
      });
      console.log(res);
      if (res.data.status == 100000) {
        message.success(res.data.msg);
        state.visibleUnbound = false;
        init();
      } else {
        message.error(res.data.msg);
      }
    };
    //解除手机登入绑定
    const postPhoneFun = async () => {
      const res = await postPhone({
        access_token: sessionStorage.getItem("token"),
        // type: 2,
        // code:state.bdPhone.code
        ...state.bdPhone,
      });
      console.log(res);
      if (res.data.status == 100000) {
        message.success(res.data.msg);
        state.visibleTel = false;
        state.bdPhone.code = undefined;
        state.bdPhone.phone = undefined;
        init();
      } else {
        message.error(res.data.msg);
      }
    };
    // const postWeixinFun = async () => {
    //   const res = await postWeixin({
    //     access_token: sessionStorage.getItem("token"),
    //     type: 2,
    //   });
    //   console.log(res);
    //   if (res.data.status == 100000) {
    //     message.success(res.data.msg);
    //   } else {
    //     message.error(res.data.msg);
    //   }
    // };
    const GenerateQrcode = async () => {
      const res = await getAppid({
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      state.appid = res.data.data.appid;
      console.log(state.appid);
      // var obj = new WxLogin({
      //   self_redirect: true,
      //   id: "login_container",
      //   appid: res.data.data.appid,
      //   scope: "snsapi_login",
      //   redirect_uri: "/user/bind_push",
      //   state: res.data.data.appid,
      //   style: "",
      //   href: "",
      // });
      // console.log(obj);
    };
    const handleOk = (e) => {
      console.log(e);
      state.visibleVx = false;
      init();
    };
    const changPwd = async () => {
      const res = await formRef.value.validate();
      console.log(res);
      if (res) {
        console.log("通过");
        const res = await getChangePwd({
          ...formState,
          access_token: sessionStorage.getItem("token"),
        });
        console.log(res);
        if (res.data.status == 100000) {
          message.success("修改成功");
          resetForm();
        } else {
          let passHift = JSON.parse(res.data.substring(0, res.data.indexOf("}") + 1))
          message.error(passHift.msg);
        }
      }
      // console.log(changePassword);
      // if (
      //   !changePassword.oldPwd.trim() ||
      //   !changePassword.newPwd.trim() ||
      //   !changePassword.confirmNewPwd.trim()
      // ) {
      //   message.warning("请填写完整");
      //   return;
      // }
      // if (changePassword.newPwd.trim() != changePassword.confirmNewPwd.trim()) {
      //   message.warning("新密码不一致");
      //   return;
      // }
      // const res = await getChangePwd({
      //   ...changePassword,
      //   access_token: sessionStorage.getItem("token"),
      // });
      // console.log(res);
      // if (res.data.status == 100000) {
      //   message.success("修改成功");
      // } else {
      //   message.error(res.data.msg);
      // }
      //  if (res.data.status == 190055)
    };
    const resetForm = () => {
      // console.log( formState)
      formRef.value.resetFields();
    };
    const sendCode = async () => {
      console.log(regTest(state.bdPhone.phone));
      if (regTest(state.bdPhone.phone)) {
        const res = await getSendCode({
          access_token: sessionStorage.getItem("token"),
          mobile: state.bdPhone.phone,
          type: state.unboundName ? 2 : 1,
        });
        console.log(res);
        state.sendCodeBtn = true;
        state.timer = setInterval(() => {
          state.time--;
          if (state.time == 0) {
            clearInterval(state.timer);
            state.time = 60;
            state.sendCodeBtn = false;
          }
        }, 1000);
      } else {
        message.warn("请输入正确的手机号");
      }
    };
    const queren = async () => {
      if (state.unboundName === "phone_binding") {
        postPhoneFun();
      } else {
        const res = await getBdPhone({
          ...state.bdPhone,
          access_token: sessionStorage.getItem("token"),
          // type: 1,
        });
        console.log(res);
        if (res.data.status == 100000) {
          message.success(res.data.msg);
          state.visibleTel = false;
          init();
        } else {
          message.error(res.data.msg);
        }
      }
    };
    const save = async () => {
      console.log(state.userInformation);
      const res = await getSave({
        ...state.userInformation,
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        init();
      } else {
        message.error(res.data.msg);
      }
    };
    const getBdWeixinFun = async () => {
      const res = await getBdWeixin({
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      state.follow_url = res.data.data.follow_url;
      state.code_url = res.data.data.code_url;
    };
    const getBdQiankeFun = async () => {
      const res = await getBdQianke({
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      state.code_url = res.data.data.code_url;
    };
    function getResult(res) {
      if (res.data.status === 100000) {
        message.success("绑定成功");
        state.visibleVx = false;
        init();
      } else {
        message.error("绑定失败");
        state.visibleVx = false;
      }
    }
    const okInit = () => {
      init();
    };
    return {
      activeKey: ref("1"),
      fileList,
      loading,
      imageUrl,
      handleChange,
      beforeUpload,
      handleBangding,
      ...toRefs(state),
      handleOk,
      // changePassword,
      changPwd,
      sendCode,
      queren,
      save,
      formState,
      formRef,
      rules,
      resetForm,
      userformRef,
      userrules,
      okUnbound,
      getResult,
      okInit,
    };
  },
  components: {
    LoadingOutlined,
    PlusOutlined,
    WechatOutlined,
    MobileOutlined,
    SafetyOutlined,
    wxlogin,
  },
};
</script>
<style>
.BaseData .ant-form-item-label {
  min-width: 120px;
}
.model2 .ant-form-item .ant-form-item-control-input-content {
  display: flex;
}
.BaseData .tab2 .ant-upload img {
  width: 180px;
  height: 180px;
}
</style>
<style  scoped>
.resetBtn {
  opacity: 0;
}
.ant-form {
  margin-top: 15px;
}
.BaseData {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.ant-tabs-line {
  height: 100%;
  overflow: auto;
}
.tips {
  margin-left: 120px;
  margin-bottom: 10px;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.tab2 {
  padding-left: 10px;
}
.item {
  /* width: 100%; */
  background-color: #f7f7f7;
  margin: 0 10px 20px 10px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}
.item .bangding {
  color: #2990e5;
  cursor: pointer;
  margin-left: 10px;
}
.item img {
  margin-right: 10px;
}
.item .anticon {
  font-size: 22px;
  background-color: #4daf29;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
  margin-right: 10px;
}
.model2 {
  margin: 20px 40px;
}
.model2 .anticon {
  font-size: 18px;
}
.model3 {
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 40px;
}
.model3item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 20px;
}
.model3item span {
  color: #2990e5;
}
.model3item img {
  width: 165px;
  height: 165px;
}
.model4 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  padding: 40px 0;
}
.model4 img {
  width: 165px;
  height: 165px;
}
/* .kdbind {
  width: 700px;
  height: 700px;
} */
</style>