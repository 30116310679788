import http from "../axios/http"

//获取用户信息
export let getUserInformation = (params) => {
    return http.get("/user/basic_update", { params })
}

//修改密码
export let getChangePwd = (params) => {
    return http.post("/user/pwd_update", params)
}

//发送验证码
export let getSendCode = (params) => {
    return http.post("/user/send_code", params)
}

//绑定手机
export let getBdPhone = (params) => {
    return http.post("/user/binding_phone", params)
}

//保存信息
export let getSave = (params) => {
    return http.post("/user/basic_update", params)
}

//获取微信接口（已改）
export let getBdWeixin = (params) => {
    return http.get("/weixin/get_binding_code", { params })
}

//获取kd连接（已改）
export let getBdQianke = (params) => {
    return http.get("/kd/get_binding_code", { params })
}

//微信绑定获取appid（已改）
export let getAppid = (params) => {
    return http.get("/index/wx_info", { params })
}
//解除手机绑定（已改）
export let postPhone = (params) => {
    return http.post("/user/relieve_phone", params )
}
//解除w微信登入绑定（已改）
export let postWeixinLogin = (params) => {
    return http.post("/user/wx_login_delete", params )
}
//解除仟客绑定（已改）
export let postKdDelete = (params) => {
    return http.post("/user/kd_delete", params )
}
//解除公众号绑定（已改）
export let postWxpushDelete = (params) => {
    return http.post("/user/wx_push_delete", params )
}